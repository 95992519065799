html {
  height: 100%;
  scroll-snap-type: y mandatory;
}

.page {
  min-height: 100%;
  margin: 0;
  font-family: "Montserrat-Alt1", sans-serif;
  font-style: normal;
  font-weight: 400;
  background: $main-bg-color;
  color: $main-text-color;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  overflow-x: hidden;

  &::before {
    @include default-pseudo-element(260px, 260px);
    right: 0;
    background: url("./../../public/img/blue-lines-square.svg") no-repeat center
      center;

    @media (max-width: $size--4) {
      width: 170px;
      height: 170px;
    }

    @media (max-width: $size--3) {
      content: none;
    }
  }
}
