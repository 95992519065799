.about {
  padding-bottom: 90px;
  margin-bottom: 0;

  @media (max-width: $size--4) {
    padding-bottom: 40px;
  }
}

.about__text-title {
  margin-bottom: 54px;
}

.about__content-container {
  display: flex;
  width: 73.4vw;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $size--8) {
    width: 85vw;
  }

  @media (max-width: $size--6) {
    width: 90vw;
  }

  @media (max-width: $size--4) {
    flex-direction: column;
  }
}

.about__text-container {
  width: 720px;

  @media (max-width: $size--8) {
    width: 575px;
  }

  @media (max-width: $size--6) {
    width: 560px;
  }

  @media (max-width: $size--4) {
    text-align: center;
  }

  @media (max-width: $size--2) {
    max-width: 100%;
  }
}

.about__text {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  &:not(:first-child) {
    margin-bottom: 25px;
  }
}
