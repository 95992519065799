// Colors
$main-text-color: #ffffff;
$title--text-color: #fdcd24;
$title-color: #0047ff;
$main-bg-color: #01010b;

// sizes
$size--1: 375px;
$size--2: 480px;
$size--2-5: 600px;
$size--3: 768px;
$size--4: 1024px;
$size--5: 1280px;
$size--6: 1368px;
$size--7: 1440px;
$size--8: 1540px;
$size--9: 1680px;
$size--10: 1920px;
