.industries__list {
  @include list-none;
  width: 73.4vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: $size--8) {
    width: 85vw;
  }

  @media (max-width: $size--6) {
    width: 90vw;
  }

  @media (max-width: $size--4) {
    flex-direction: column;
    align-items: center;
  }
}
