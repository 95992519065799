.pmo {
  width: 617px;
  height: 576px;
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;

  @media (max-width: $size--5) {
    width: 550px;
  }

  @media (max-width: $size--4) {
    width: 550px;
    height: 570px;
    margin-bottom: 30px;
  }

  @media (max-width: $size--3) {
    width: 505px;
    height: 530px;
  }

  @media (max-width: $size--2) {
    width: 90vw;
    height: 510px;
    margin-bottom: 30px;
  }

  @media (max-width: $size--1) {
    width: 85vw;
    height: 500px;
  }
}

.block-animation--active {
  & .pmo__decor-container {
    @media (min-width: $size--3) {
      animation: pmo-list-animation linear 12s infinite forwards;
    }
  }

  & .pmo__item {
    @media (min-width: $size--3) {
      animation: pmo-list-animation-1 linear 12s infinite forwards;
    }
  }

  // Animations
  @keyframes pmo-list-animation {
    from {
      rotate: 0;
    }
    to {
      rotate: 360deg;
    }
  }
  @keyframes pmo-list-animation-1 {
    from {
      rotate: 0;
    }
    to {
      rotate: -360deg;
    }
  }
}

.pmo__decor-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("./../../public/img/pmo-bg.svg") no-repeat center center;

  @media (max-width: $size--3) {
    background-size: 340px;
  }

  @media (max-width: $size--2) {
    height: 90%;
  }
}

.pmo__tilte {
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  /* identical to box height, or 108% */

  color: #fdcd24;
  margin: 0;

  @media (max-width: $size--2) {
    font-size: 45px;
    line-height: 45px;
  }
}

.pmo__under-title {
  font-weight: 300;
  font-size: 25px;
  line-height: 25px;
  /* or 260% */

  text-transform: uppercase;
  text-align: center;

  color: #fdcd24;
  margin: 0;

  @media (max-width: $size--2) {
    font-size: 20px;
    line-height: 20px;
  }
}

.pmo__title-container {
  width: fit-content;
  margin: 0 auto;
}

.pmo__list {
  @include list-none;
}

.pmo__item {
  position: absolute;

  &--planning {
    top: 0;
    right: 228.5px;
  }

  &--monitoring {
    top: 155px;
    right: 20px;
  }

  &--managing {
    bottom: 10px;
    right: 110px;
  }

  &--controlling {
    bottom: 10px;
    left: 110px;
  }

  &--reporting {
    top: 155px;
    left: 20px;
  }

  @media (max-width: $size--5) {
    &--planning {
      top: 0;
      right: 195.5px;
    }

    &--monitoring {
      top: 175px;
      right: 0;
    }

    &--managing {
      bottom: 25px;
      right: 80px;
    }

    &--controlling {
      bottom: 25px;
      left: 80px;
    }

    &--reporting {
      top: 160px;
      left: 0;
    }
  }

  @media (max-width: $size--3) {
    &--planning {
      top: 0;
      right: 165.5px;
    }

    &--monitoring {
      top: 155px;
      right: 0;
    }

    &--managing {
      bottom: 25px;
      right: 80px;
    }

    &--controlling {
      bottom: 25px;
      left: 80px;
    }

    &--reporting {
      top: 155px;
      left: 0;
    }
  }

  @media (max-width: $size--2) {
    &--planning {
      top: 0;
      right: 140.5px;
    }

    &--monitoring {
      top: 160px;
      right: -20px;
    }

    &--managing {
      bottom: 25px;
      right: 50px;
    }

    &--controlling {
      bottom: 25px;
      left: 50px;
    }

    &--reporting {
      top: 160px;
      left: -20px;
    }
  }

  @media (max-width: $size--1) {
    &--planning {
      top: 0;
      right: 89.5px;
    }

    &--monitoring {
      top: 124px;
      right: -40px;
    }

    &--managing {
      bottom: 25px;
      right: 10px;
    }

    &--controlling {
      bottom: 25px;
      left: 10px;
    }

    &--reporting {
      top: 124px;
      left: -40px;
    }
  }
}

.pmo__img {
  width: 160px;
  height: 160px;

  @media (max-width: $size--2) {
    width: 120px;
    height: 120px;
  }
}
