.main-footer {
  max-width: 1920px;
  box-sizing: border-box;
  padding: 0 7.6vw;
  align-items: center;
  min-width: 100%;
  margin: 0 auto;
  overflow-y: clip;
}

.block-animation--active {
  & .main-footer__container {
    &::before {
      animation: footer-line 0.5s linear forwards;
    }
  }
}

.main-footer__logo {
  width: 120px;
  height: 25px;
  @media (max-width: $size--3) {
    order: -1;
    width: 180px;
    height: auto;
  }
  justify-self: flex-end;
}

.main-footer__container {
  padding: 30px 0;
  margin: 0 auto;

  position: relative;

  &-animation {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;

    @media (max-width: $size--3) {
      gap: 20px;
      align-items: center;
    }
  }

  &::before {
    @include default-pseudo-element(0, 1px);
    top: 0;
    left: 50%;
    background-color: #2f0dff;
  }
}

.main-footer__top-part {
  display: flex;

  gap: 10px;
  align-items: flex-end;
  justify-items: center;
  width: 100%;

  @media (max-width: $size--3) {
    display: flex;
    flex-direction: column;
    gap: 35px;
    align-items: center;

    justify-content: center;
  }
}

.main-footer__copyright {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  /* identical to box height, or 167% */
  margin: 0;

  color: #e5e7fa;
  @media (max-width: $size--3) {
    order: 3;
  }
  justify-self: flex-start;
}

// Animiations
@keyframes footer-line {
  from {
    width: 0%;
    left: 50%;
  }
  to {
    left: 0;
    width: 100%;
  }
}

.main-footer__contact {
  display: flex;
  gap: 40px;
  margin: 0 auto;

  & a {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    height: 100%;
    transition: 0.85s;

    &::before {
      @include default-pseudo-element(0px, 3px);
      transition: 0.85s;
      bottom: -5px;
      left: 50%;
      background-color: #fdcd24;
      transition: 0.85s;

      @media (max-width: $size--2) {
        bottom: 1px;
        height: 2px;
      }
    }
    &:hover {
      transform: scale(1.15);
      transition: 0.85s;

      &::before {
        width: 100%;
        left: 0;
        transition: 0.85s;
      }
    }

    @media (max-width: $size--2) {
      font-size: 14px;
    }

    @media (max-width: $size--1) {
      font-size: 12px;
    }
  }

  @media (max-width: $size--4) {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
}
.main-footer__social-media {
  display: block;
  width: fit-content;
  transition: 0.7s;

  &:hover {
    scale: 1.2;
    transition: 0.7s;
  }
}

.main-footer__social-media-icon {
  width: 32px;
  height: 32px;
}

.main-footer__author {
  display: flex;
}

.main-footer__author-link {
  margin-left: 10px;
  color: #fdcd24;
  text-decoration: none;
  cursor: pointer;
  display: block;
  height: 100%;
  transition: 0.85s;

  &::before {
    @include default-pseudo-element(0px, 3px);
    transition: 0.85s;
    bottom: -5px;
    left: 50%;
    background-color: #fdcd24;
    transition: 0.85s;

    @media (max-width: $size--2) {
      bottom: 1px;
      height: 1px;
    }
  }
  &:hover {
    transform: scale(1.1);
    transition: 0.85s;

    &::before {
      width: 100%;
      left: 0;
      transition: 0.85s;
    }
  }
}
