.block {
  z-index: 2;
  position: relative;
  margin-bottom: 150px;
  overflow: hidden;

  @media (max-width: $size--3) {
    margin-bottom: 100px;
  }
}
