.text {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #e5e7fa;

  &--white {
    color: $main-text-color;
  }
}
