.offers {
  overflow: hidden;
  margin-bottom: 100px;

  @media (max-width: $size--3) {
    margin-bottom: 70px;
  }
}

.offers__text-title {
  margin-bottom: 50px;
}

.offers__list {
  @include list-none;
  width: fit-content;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: space-between;

  @media (max-width: $size--5) {
    gap: 10px;
  }

  @media (max-width: $size--2-5) {
    display: block;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
