.do-more {
  padding: 150px 0;
  margin-bottom: 0;
  overflow: hidden;

  @media (max-width: $size--3) {
    display: flex;
    flex-direction: column;
    padding: 75px 0;
  }
}

.do-more__title {
  @media (max-width: $size--3) {
    order: 0;
  }
}

.do-more__text-title {
  @media (max-width: $size--3) {
    order: 1;
  }
}

.do-more__text-title {
  margin-top: 0;
  margin-bottom: 50px;
}

.do-more__menu-list {
  @include list-none;
  display: flex;
  max-width: 1440px;
  width: 73.4vw;
  margin: 0 auto;
  justify-content: space-between;
  margin-bottom: 150px;

  @media (max-width: $size--9) {
    width: 90vw;
  }

  @media (max-width: $size--3) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    order: 2;
    margin-bottom: 112px;
  }
}

.do-more__menu-item {
  width: fit-content;

  @media (max-width: $size--10) {
    max-width: 320px;
  }

  @media (max-width: $size--6) {
    width: min-content;
  }

  @media (max-width: $size--3) {
    width: auto;
    max-width: 80%;
  }
}

.do-more__menu-button {
  text-align: center;
  text-decoration: none;
  @include button-none;
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  /* or 100% */
  transition: 0.85s;
  cursor: pointer;
  display: block;
  width: fit-content;
  position: relative;

  color: #ffffff;
  @media (max-width: $size--4) {
    font-size: 20px;
    line-height: 20px;
    margin-top: 0;
  }

  &::before {
    @include default-pseudo-element(0, 3px);
    transition: 0.85s;
    bottom: -10px;
    left: 50%;
    background-color: $title--text-color;
  }

  &:hover {
    transform: scale(1.15);

    &::before {
      width: 100%;
      left: 0;
      transition: 0.85s;
    }
  }

  &--active {
    color: $title--text-color;
  }
}

.do-more__button {
  @include button-none;
  padding: 10px 30px;
  font-size: 20px;
  line-height: 35px;
  /* identical to box height, or 175% */

  text-align: center;
  display: block;

  color: #ffffff;
  background: linear-gradient(180deg, #0047ff 0%, #2f0dff 100%);
  box-shadow: 0px 10px 10px rgba(0, 71, 255, 0.25);
  margin: 0 auto;
  cursor: pointer;
  margin-top: 30px;
  transition: 0.5s;
  max-width: 280px;
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
    background: linear-gradient(180deg, #003fde 0%, #250ec1 100%);
    box-shadow: 0px 10px 10px rgba(0, 71, 255, 0.4);
  }

  @media (max-width: $size--3) {
    order: 3;
    margin-bottom: 60px;
  }
}

.do-more__list {
  max-width: 1600px;
  @include list-none;
  position: relative;
  margin: 0 auto;

  @media (max-width: $size--3) {
    width: 100%;
    order: 4;
  }
}

.do-more__item {
  padding: 0 50px 100px 50px;
  width: 28vw;
  box-sizing: border-box;
  position: absolute;
  right: -70vw;
  top: 75px;
  z-index: 1;
  transition: 1s;

  &::before {
    @include default-pseudo-element(100%, 75%);
    background: linear-gradient(
      180deg,
      rgba(0, 71, 255, 0) -12.47%,
      rgba(47, 13, 255, 0.5) 92.62%
    );
    z-index: -1;
    bottom: 0;
    left: 0;
  }

  &::after {
    @include default-pseudo-element(100%, 75%);
    background: rgba(13, 13, 13, 0.5);
    backdrop-filter: blur(12.5px);
    bottom: 0;
    left: 0;
    z-index: -2;
  }

  @media (max-width: $size--5) {
    padding: 0 35px 80px 35px;
    // top: 50px;
    top: 0px;
  }

  @media (max-width: $size--4) {
    padding: 0 35px 60px 35px;
    // top: 50px;
    top: 0px;
  }

  @media (max-width: $size--3) {
    top: -20px;
    height: fit-content;
    width: 100vw;
    height: 620px;
    position: relative;
    padding: 80px 20px 60px 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 0.75fr 2fr 1fr;
    flex-direction: column;
    right: 0;
    gap: 25px;

    & img,
    & h3,
    & p {
      max-height: 100%;
    }

    &::before {
      height: 100%;
    }
  }

  &--active {
    @media (min-width: $size--3) {
      // right: 7vw;
      right: 0vw;
      transition: 1s;
    }
  }
}

.do-more__img {
  margin-bottom: 50px;
  width: 172px;
  height: 200px;

  &.animation {
    opacity: 0;
    transition: 450ms;
  }

  @media (max-width: $size--4) {
    width: 150px;
    height: auto;
    margin-bottom: 40px;
  }

  @media (max-width: $size--4) {
    width: 120px;
    height: auto;
    margin-bottom: 20px;
  }

  @media (max-width: $size--3) {
    order: 1;
    padding: 0 20px;
    max-height: 100%;
    height: 90%;
    transition: 1s;
    width: auto;
  }
}

.do-more__item-title {
  font-weight: 900;
  font-size: 35px;
  line-height: 35px;
  /* identical to box height, or 71% */

  color: #ffffff;
  @include margin-bottom(20px);

  @media (max-width: $size--3) {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 0px;
    order: 0;
  }
}

.do-more__item-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  /* or 167% */

  color: #e5e7fa;
  margin: 0;

  @media (max-width: $size--3) {
    font-size: 15px;
    line-height: 25px;
    padding: 0 20px;
    order: 2;
  }
}

// Animations
@keyframes menu-button--hover {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes menu-button--no-hover {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
