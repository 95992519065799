.light-container {
  width: 100%;
  position: absolute;
  overflow-x: clip;
  min-height: 100%;
  top: 0;
  z-index: -1;
}

.block-animation--active {
  .light {
    &--1 {
      transform: translateX(-1300px) translateY(1000px);
      transition: 4s;
      will-change: transform;
    }
  }
}

.light {
  position: absolute;
  z-index: -1;

  &--1 {
    background: radial-gradient(50% 50% at 50% 50%, #001aff 0%, #000000 100%);
    opacity: 0.5;
    width: 2041px;
    height: 2041px;
    filter: blur(50px);
    top: -2000px;
    right: -2000px;
    border-radius: 50%;
  }

  &--2 {
    width: 100%;
    height: 890px;
    bottom: 0;
    right: 0;

    background: linear-gradient(
      180deg,
      rgba(0, 71, 255, 0) 0%,
      rgba(47, 13, 255, 0.5) 100%
    );

    @media (max-width: $size--4) {
      height: 100%;
    }
  }

  &--3 {
    width: 1097px;
    height: 1097px;
    border-radius: 50%;
    bottom: 0px;

    background: radial-gradient(
      50% 50% at 50% 50%,
      #001aff 0%,
      rgba(0, 26, 255, 0.6) 20%,
      rgba(0, 26, 255, 0.2) 55%,
      rgba(0, 26, 255, 0.1) 70%,
      transparent 100%
    );
    filter: blur(10px);

    @media (max-width: $size--5) {
      background: radial-gradient(
        50% 50% at 50% 50%,
        #001aff 0%,
        rgba(0, 26, 255, 0.7) 5%,
        rgba(0, 26, 255, 0.6) 10%,
        rgba(0, 26, 255, 0.5) 20%,
        rgba(0, 26, 255, 0.2) 55%,
        rgba(0, 26, 255, 0.1) 70%,
        transparent 100%
      );

      width: 800px;
      height: 800px;
      bottom: 120px;
      left: -40px;
    }

    @media (max-width: $size--4) {
      width: 100vw;
      height: 100vw;
      bottom: 450px;
      left: 0px;
    }

    @media (max-width: $size--3) {
    }
  }

  &--4 {
    width: 219px;
    height: 219px;
    right: 40px;
    bottom: 347px;

    background: radial-gradient(
      50% 50% at 50% 50%,
      #4e29ff 0%,
      rgba(78, 41, 255, 0) 100%
    );
    opacity: 0.8;
    filter: blur(25px);

    @media (max-width: $size--2) {
      display: none;
    }
  }

  &--5 {
    width: 1768px;
    height: 1768px;
    top: 0;
    right: -894px;
    z-index: -2;

    background: radial-gradient(
      50% 50% at 50% 50%,
      #001aff 0%,
      transparent 100%
    );
    filter: blur(10px);

    @media (max-width: $size--5) {
      width: 1000px;
      height: 1500px;
      top: 200px;
      right: -447px;
    }

    @media (max-width: $size--3) {
      width: 650px;
      height: 1000px;
      top: 200px;
      right: -287px;
    }

    @media (max-width: $size--2) {
      display: none;
    }
  }

  &--6 {
    width: 264px;
    height: 264px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #4e29ff 0%,
      rgba(78, 41, 255, 0) 100%
    );
    opacity: 0.2;
    filter: blur(25px);
    left: 381px;
    bottom: 412px;
  }
}
