@mixin list-none {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin button-none {
  background: none;
  border: none;
  padding: 0;
}

@mixin wrapper($padding, $width: 100vw, $max-width: 100vw) {
  padding: 0 $padding;
  width: calc($width - ($padding * 2));
  margin: 0 auto;
  max-width: calc($max-width - ($padding * 2));
}

@mixin margin-bottom($margin) {
  margin: 0;
  margin-bottom: $margin;
}

@mixin default-pseudo-element($width, $height: $width) {
  content: "";
  position: absolute;
  width: $width;
  height: $height;
}

@mixin centering-pseudo-element() {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centering-hight-pseudo-element() {
  top: 50%;
  transform: translateY(-50%);
}

@mixin rotate-centering-hight-element($rotate) {
  top: 50%;
  transform: translateY(-50%) rotate($rotate);
}

@mixin centering-width-pseudo-element() {
  left: 50%;
  transform: translateX(-50%);
}
