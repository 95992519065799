@mixin scaleAnimation($el, $scaleTo, $duraction, $scaleFrom: 0, $delay: 0s) {
  .block-animation {
    &#{$el} {
      scale: $scaleFrom;
    }
    &--active {
      &#{$el} {
        animation: scale $duraction $delay forwards ease-in-out;
        will-change: scale;
      }
    }
  }

  @keyframes scale {
    to {
      scale: $scaleTo;
    }
  }
}

@mixin moveAnimation(
  $el,
  $duraction,
  $distanse: 20vw,
  $directionFrom: "right",
  $delay: 0s
) {
  .block-animation {
    &#{$el} {
      opacity: 0;
      @if ($directionFrom == "right") {
        transform: translateX($distanse);
      }
      @if ($directionFrom == "left") {
        transform: translateX(calc(-1 * $distanse));
      }
      @if ($directionFrom == "bottom") {
        transform: translateY($distanse);
      }
      @if ($directionFrom == "top") {
        transform: translateY(calc(-1 * $distanse));
      }
    }

    &--active {
      &#{$el} {
        animation: move $duraction $delay forwards ease-in-out;
        will-change: opacity, transform;
      }
    }
  }

  @keyframes move {
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
}

@include scaleAnimation(".industry", 1, 1.2s);
@include moveAnimation(".title--animation", 1.2s, 35vw, "left");
@include moveAnimation(".title--text", 1.2s);
@include scaleAnimation(".do-more-bg__map", 1, 1.2s, 0.4);

@media (max-width: $size--3) {
  @include moveAnimation(".container-slider", 1.2s, 12vw, "bottom");
}
@media (min-width: $size--3) {
  @include scaleAnimation(".do-more-bg__img", 1, 1.2s, 0, 0.6s);
}
@include moveAnimation(
  ".main-footer__container .main-footer__container-animation",
  1.2s,
  25vw,
  "bottom"
);
@include scaleAnimation(".first-screen__logo", 1, 2s, 0.2);
@include moveAnimation(".first-screen__text", 2s, 10vh, "bottom");
@include scaleAnimation(".project-description__title", 1, 1.5s);
@include moveAnimation(".project-description__text", 1.5s, 10vh, "bottom");

@media (min-width: $size--4) {
  @include moveAnimation(".about__text", 1.2s);
}
@media (max-width: $size--4) {
  @include moveAnimation(".about__text--1", 1.2s, 20vw, "left");
  @include moveAnimation(".about__text--2", 1.2s);
}

@include scaleAnimation(".do-more__menu-item", 1, 1.5s);
@include moveAnimation(".do-more__button", 1.2s, 10vh, "bottom");

@media (min-width: $size--3) {
  @include moveAnimation(".offer__title", 1.3s, 20vw, "left");
  @include moveAnimation(".offer__opportunity", 1.3s, 10vh, "top");
  @include moveAnimation(
    ".offer__price-animation-container",
    1.3s,
    10vh,
    "bottom"
  );
}

@include scaleAnimation(".pmo", 1, 1s, 0.3);
