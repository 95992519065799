.first-screen {
  padding-top: 255px;
  padding-bottom: 132px;
  box-sizing: border-box;
  text-align: center;
  z-index: 2;
  position: relative;

  @media (max-width: $size--8) {
    padding-top: 265px;
  }

  @media (max-width: $size--7) {
    padding-top: 240px;
  }

  @media (max-width: $size--6) {
    padding-top: 170px;
  }

  @media (max-width: $size--5) {
    padding-top: 170px;
  }

  @media (max-width: $size--4) {
    background-position-y: 100px;
    background-size: 120%;
    min-height: max-content;
    padding-bottom: 110px;
    padding-top: 140px;
  }

  @media (max-width: $size--3) {
    padding-top: 65px;
  }
}

.scroll-text {
  @include centering-width-pseudo-element();
  position: absolute;
  font-size: 20px;
  font-weight: 300;
  top: 80vh;
  animation: toScroll 3s forwards infinite;
  cursor: pointer;
  height: 70px;

  &::before {
    @include default-pseudo-element(2px, 40px);
    @include centering-width-pseudo-element();
    background-color: #ffffff;
    bottom: 0px;
  }

  &::after {
    @include default-pseudo-element(25px);
    @include centering-width-pseudo-element();
    background: url(./../../public/img/chevron-down.svg);
    bottom: -17px;
  }

  @media (max-width: $size--3) {
    display: none;
  }
  @media (max-width: $size--5) {
    top: 60vh;
    animation-name: toScroll--size-3;
  }
  @media (max-width: $size--7) {
    top: 60vh;
    animation-name: toScroll--size-7;
  }
}

@keyframes toScroll {
  from {
    top: 80vh;
  }
  50% {
    top: 82vh;
  }
  100% {
    top: 80vh;
  }
}

@keyframes toScroll--size-3 {
  from {
    top: 60vh;
  }
  50% {
    top: 62vh;
  }
  100% {
    top: 60vh;
  }
}

@keyframes toScroll--size-7 {
  from {
    top: 60vh;
  }
  50% {
    top: 62vh;
  }
  100% {
    top: 60vh;
  }
}

.block-animation--active {
  & svg {
    & path {
      animation: dash 6.5s linear forwards;
      will-change: stroke-dashoffset;

      @keyframes dash {
        from {
          stroke-dashoffset: 13000;
        }
        to {
          stroke-dashoffset: 0;
        }
      }
    }
  }
}

.first-screen__bg {
  position: absolute;
  z-index: -1;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;

  @media (max-width: $size--10) {
    top: 33%;
  }

  @media (max-width: $size--9) {
    width: 80%;
    top: 33%;
  }

  @media (max-width: $size--8) {
    width: 75%;
    top: 37%;
  }

  @media (max-width: $size--7) {
    width: 70%;
    top: 35%;
  }

  @media (max-width: $size--6) {
    top: 33%;
    width: 65%;
  }

  @media (max-width: $size--5) {
    top: 31%;
  }

  @media (max-width: $size--4) {
    top: 28%;
    width: 70%;
  }

  @media (max-width: $size--3) {
    top: 25%;
    width: 90%;
  }

  @media (max-width: 650px) {
    top: 20%;
    width: 90%;
  }

  @media (max-width: $size--2) {
    top: 15%;
  }

  @media (max-width: $size--1) {
    top: 13%;
  }

  & svg {
    width: 100%;

    & path {
      stroke-dasharray: 11000;
      stroke-dashoffset: 11000;
    }
  }
}

.first-screen__logo {
  margin-bottom: 50.6px;
  width: 70vw;

  @media (max-width: $size--7) {
    width: 55vw;
    margin-bottom: 40.6px;
  }

  @media (max-width: $size--6) {
    width: 55vw;
  }

  @media (max-width: $size--5) {
    width: 50vw;
  }

  @media (max-width: $size--5) {
  }

  @media (max-width: $size--4) {
    width: 50vw;
  }

  @media (max-width: $size--3) {
    margin-bottom: 30.6px;
    width: 65vw;
  }
}

.first-screen__text {
  font-weight: 400;
  font-size: 28px;
  line-height: 26px;
  /* or 94% */

  text-align: center;

  color: #e5e7fa;
  @include margin-bottom(500px);

  @media (max-width: $size--8) {
    margin-bottom: 440px;
  }

  @media (max-width: $size--7) {
    margin-bottom: 440px;
    font-size: 24px;
    line-height: 22px;
  }

  @media (max-width: $size--6) {
    margin-bottom: 370px;
    font-size: 24px;
    line-height: 22px;
  }

  @media (max-width: $size--5) {
    margin-bottom: 350px;
    font-size: 20px;
    line-height: 18px;
  }

  @media (max-width: $size--4) {
    margin-bottom: 330px;
    font-size: 20px;
    line-height: 18px;
  }

  @media (max-width: $size--3) {
    margin-bottom: 170px;
    font-size: 18px;
    line-height: 20px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: $size--2) {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
  }
}

.project-description__title {
  font-weight: 500;
  width: 80%;
  font-size: 45px;
  line-height: 75px;
  letter-spacing: 0.5mm;
  /* or 167% */

  margin: 0 auto;
  text-transform: uppercase;

  color: #ffffff;
  margin-bottom: 30px;

  @media (max-width: $size--6) {
    font-size: 40px;
    line-height: 65px;
  }

  @media (max-width: $size--5) {
    font-size: 35px;
    line-height: 60px;
  }

  @media (max-width: $size--4) {
    font-size: 25px;
    line-height: 40px;
  }

  @media (max-width: $size--3) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: $size--2) {
    font-size: 20px;
    line-height: 25px;
  }
}

.project-description__text {
  width: 80%;
  max-width: 980px;
  margin: 0 auto;

  @media (max-width: $size--3) {
    max-width: 600px;
  }
}
