.title {
  font-weight: 100;
  font-size: 100px;
  line-height: 120px;
  /* or 120% */
  margin: 0;

  text-align: center;
  text-transform: uppercase;

  color: $title-color;

  @media (max-width: $size--4) {
    font-size: 70px;
    line-height: 90px;
  }

  @media (max-width: $size--3) {
    font-size: 50px;
    line-height: 70px;
  }

  &--h3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    /* or 100% */

    text-transform: none;

    color: $main-text-color;

    @media (max-width: $size--4) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &--text {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
    font-size: 80px;
    line-height: 65px;
    /* identical to box height, or 81% */

    text-align: center;

    color: $title--text-color;
    margin-bottom: 50px;

    @media (max-width: $size--4) {
      font-size: 60px;
      line-height: 45px;
    }

    @media (max-width: $size--3) {
      font-size: 40px;
      line-height: 45px;
    }
  }
}
