.industry {
  width: 240px;
  text-align: center;

  @media (max-width: $size--4) {
    width: 70vw;

    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
}

.industry__img-container {
  position: relative;
  box-sizing: border-box;
  z-index: 2;

  &::before {
    @include default-pseudo-element(180px);
    background: linear-gradient(180deg, #0047ff 0%, #000000 100%);
    left: 30px;
    bottom: -17px;
    opacity: 0.1;
    border-radius: 50%;

    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    @include default-pseudo-element(214px);
    background: radial-gradient(50% 50% at 50% 50%, #001aff 0%, #000000 100%);
    filter: blur(50px);
    left: 13px;
    bottom: -25px;
    z-index: -1;

    left: 50%;
    transform: translateX(-50%);
  }
}

.industry__title {
  @include margin-bottom(20px);
  margin-top: 55px;
}

.industry__img {
  &--energy {
    width: 133px;
    height: 193px;
  }

  &--agriTech {
    width: 150px;
    height: 174px;
  }

  &--space {
    width: 78px;
    height: 210px;
  }

  &--medTech {
    width: 150px;
    height: 160px;
  }

  &--ci {
    width: 170px;
    height: 157px;
  }
}
