.do-more-bg {
  margin: 0 auto;
  margin-top: 30px;
  width: min-content;
  position: relative;

  &--desktop {
    width: 80vw;
    @media (max-width: $size--3) {
      display: none;
    }
  }

  &--mobile {
    height: 140px;
    width: 80%;
    margin: 0 auto;

    & .do-more-bg__img--plant {
      width: 80px;
    }
    & .do-more-bg__img--water-wall {
      width: 90px;
    }
    & .do-more-bg__img--energy {
      width: 60px;
    }
    & .do-more-bg__img--incubator {
      width: 51px;
    }
    & .do-more-bg__img--servers {
      width: 79px;
    }
    & .do-more-bg__img--building {
      width: 76px;
    }
    & .do-more-bg__img--drone {
      width: 100px;
    }
    & .do-more-bg__img--laser {
      width: 59px;
    }
    & .do-more-bg__img--medTech {
      width: 75px;
    }
    & .do-more-bg__img--agriTech {
      width: 69px;
    }
    & .do-more-bg__img--chemistry {
      width: 64px;
    }
    & .do-more-bg__img--truck {
      width: 93px;
    }
    & .do-more-bg__img--atonomic {
      width: 80px;
    }
    & .do-more-bg__img--battery {
      width: 58px;
    }
  }
}

.do-more-bg__map {
  z-index: -1;
  position: relative;

  width: 100%;
  height: auto;

  @media (max-width: $size--3) {
    display: none;
  }
}

.do-more-bg__img {
  position: absolute;
  transition: 0.7s;
  cursor: pointer;
  transition: 0.7s !important;

  &:hover {
    transform: scale(1.2) !important;
  }

  &--active {
    transform: scale(1.3) !important;
  }

  &--plant {
    top: 37.8%;
    left: 8.7%;
  }
  &--water-wall {
    top: 20.2%;
    left: 14%;
  }
  &--energy {
    top: 12%;
    left: 25.5%;
  }
  &--incubator {
    top: 31.7%;
    left: 23.7%;
  }
  &--servers {
    top: 26%;
    left: 33.2%;
  }
  &--building {
    top: 14.5%;
    left: 42%;
  }
  &--drone {
    top: 9.3%;
    left: 50%;
  }
  &--laser {
    top: 19%;
    right: 37.2%;
  }
  &--medTech {
    top: 36%;
    left: 48%;
  }
  &--agriTech {
    top: 41%;
    left: 40.3%;
  }
  &--chemistry {
    top: 55%;
    left: 44.2%;
  }
  &--truck {
    top: 48%;
    right: 41.5%;
  }
  &--atonomic {
    top: 36%;
    right: 34%;
  }
  &--battery {
    top: 58%;
    right: 36.5%;
  }

  height: auto;
  &--plant {
    width: 5.8%;
  }
  &--water-wall {
    width: 6.25%;
  }
  &--energy {
    width: 4.2%;
  }
  &--incubator {
    width: 4%;
  }
  &--servers {
    width: 5.7%;
  }
  &--building {
    width: 5.6%;
  }
  &--drone {
    width: 6.8%;
  }
  &--laser {
    width: 4.15%;
  }
  &--medTech {
    width: 5.2%;
  }
  &--agriTech {
    width: 4.8%;
  }
  &--chemistry {
    width: 4.6%;
  }
  &--truck {
    width: 6.4%;
  }
  &--atonomic {
    width: 5.6%;
  }
  &--battery {
    width: 4.15%;
  }
}

.swiper-slide {
  & img {
    position: static;
  }

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slider-button {
  width: 40px !important;
  height: 40px !important;
  background: url("./../../public/img/arrow-offer.svg") no-repeat center center;
  background-size: cover;
  position: absolute;
  z-index: 10;
  @include centering-hight-pseudo-element;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5s;
  transform: translateY(0%);
  &:hover {
    scale: 1.2;
    transition: 0.5s;
  }
}

.swiper-button-next {
  right: 20px;
  // @include rotate-centering-hight-element(180deg);
  transform: translateY(0%) rotate(180deg);
}

.swiper-button-prev {
  left: 20px;
}

.swiper-button-next:after, .swiper-button-prev:after {
  content: none !important;
}

.container-slider {
  position: relative;
  order: 4;
  display: none !important;

  @media (max-width: $size--3) {
    display: block !important;
  }
}

.swiper-pagination {
  bottom: -10px !important;
  display: flex;
  justify-content: space-between;
  max-width: 80% !important;
  margin: 0 auto !important;
  position: relative !important;
  left: auto !important;
}

.swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
  border: 2px solid $title-color;
  background-color: transparent;
  opacity: 1 !important;
  transition: 0.2s;

  &-active {
    transition: 0.2s;
    background-color: $title--text-color !important;
    border-color: $title--text-color !important;
  }
}
