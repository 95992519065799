.offer {
  background: rgba(13, 13, 13, 0.5);
  backdrop-filter: blur(12.5px);
  position: relative;
  padding: 40px 40px 15px 40px;
  box-sizing: border-box;
  width: 420px;

  &::before {
    @include default-pseudo-element(100%, 10px);
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, #0047ff 0%, #2f0dff 100%);
  }

  @media (max-width: $size--5) {
    width: 32vw;
  }

  @media (max-width: $size--4) {
    padding: 30px 30px 15px 30px;
  }

  @media (max-width: $size--2-5) {
    display: none;
  }

  &--active {
    background: linear-gradient(180deg, #0047ff 0%, #2f0dff 98.68%);
    backdrop-filter: none;

    &::before {
      content: none;
    }

    & .offer__opportunity::before {
      background-image: url("./../../public/img/star-in--active.svg");
    }

    & .offer__opportunity--active::before {
      background-image: url("./../../public/img/star--yellow.svg");
    }

    @media (max-width: $size--2-5) {
      width: 100%;
      display: block;

      & .offer__slider-button {
        @include button-none;
        display: block;
        width: 45px;
        height: 45px;
        background: url("./../../public/img/arrow-offer.svg") no-repeat center
          center;
        background-size: contain;
        top: 35px;
        right: 10px;
        cursor: pointer;
        position: absolute;

        &--left {
          left: 10px;
        }

        &--right {
          right: 10px;
          transform: rotate(180deg);
        }
      }
    }
  }
}

.offer__title {
  font-weight: 200;
  font-size: 40px;
  line-height: 50px;
  /* identical to box height, or 125% */

  text-transform: uppercase;
  position: relative;

  color: $title--text-color;
  @include margin-bottom(60px);

  &::before {
    @include default-pseudo-element(100px, 10px);
    border: 1px solid #ffffff;
    bottom: -10px;
    box-sizing: border-box;

    @media (max-width: $size--3) {
      width: 60px;
    }
  }

  @media (max-width: $size--4) {
    font-size: 30px;
  }

  @media (max-width: $size--3) {
    font-size: 20px;
  }

  @media (max-width: $size--2-5) {
    font-size: 30px;
    line-height: 40px;
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 48px;
  }

  @media (max-width: $size--1) {
    font-size: 25px;
    line-height: 35px;
  }
}

.offer__opportunities {
  @include list-none;
  padding-left: 40px;
  margin-bottom: 40px;

  @media (max-width: $size--3) {
    padding-left: 25px;
  }

  @media (max-width: $size--2-5) {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}

.offer__opportunity {
  position: relative;
  font-weight: 300;
  font-size: 17px;
  line-height: 30px;
  /* or 176% */

  color: #ffffff;

  opacity: 0.5;

  &::before {
    @include default-pseudo-element(20px);
    background: url("./../../public/img/star.svg") no-repeat center;
    left: -40px;
    top: 3px;
  }

  &--active {
    opacity: 1;

    &::before {
      background-image: url("./../../public/img/star--blue.svg");
    }
  }

  @media (max-width: $size--3) {
    font-size: 14px;
    line-height: 25px;
  }

  @media (max-width: $size--2-5) {
    width: fit-content;
  }
}

.offer__price {
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  /* or 50% */

  color: #ffffff;
  margin: 0;

  &--old {
    margin-top: 10px;
    margin-left: 40px;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    width: fit-content;

    /* identical to box height, or 83% */

    color: #ffffff;
    opacity: 0.5;
    position: relative;

    &::before {
      @include default-pseudo-element(100%, 1px);
      background: #d60335;
      top: 10px;
    }

    @media (max-width: $size--3) {
      font-size: 14px;
      line-height: 20px;
    }

    @media (max-width: $size--2-5) {
      width: fit-content;
      margin: 0 auto;
    }
  }

  @media (max-width: $size--3) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: $size--2-5) {
    width: fit-content;
    margin: 0 auto;
  }
}

.offer__amount {
  font-weight: 800;
  font-size: 50px;
  /* or 50% */

  color: $title--text-color;

  &--old {
    font-weight: 400;
    font-size: 30px;
    line-height: 25px;

    @media (max-width: $size--3) {
      font-size: 20px;
      line-height: 15px;
    }
  }

  @media (max-width: $size--3) {
    font-size: 35px;
  }
}

.offer__slider-button {
  display: none;
  position: relative;
}
